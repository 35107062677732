import React from 'react'


export const Coverage = () => {
    return (
        <div className="card shadow mb-4">
            <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">Cobertura</h6>
            </div>
            <div className="card-body">
                <img src="https://iosynergi.com/coverage.jpg" alt="Cobertura" style={{ width: '850px', height: 'auto' }} />

            </div>
        </div>
    )
}
