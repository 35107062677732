import React, { useContext, useEffect } from 'react';
import Thing from './Thing';
import thingsContext from '../../context/things/thingsContext';

const ListThings = () => {

    //                                                      //Extrae los dispositivos del state inicial.
    const thingContext = useContext(thingsContext);
    const { things, getThings } = thingContext;

    ///                                                     //Cuando carga el componente
    useEffect( () => {
        //                                                  //Se obtienes los things.
        getThings();

        // eslint-disable-next-line
    }, [])

    if( things.length === 0) return null;

    return (

        <table className="table table-bordered table-hover" id="dataTable" width="100%" cellSpacing="0">
            <thead>
                <tr>
                    <th>id</th>
                    <th>Alias</th>
                    <th>Data</th>
                    <th>Fecha de actualización</th>
                </tr>
            </thead>
            <tbody>
                {things.map(thing => (
                    <Thing
                        key={thing._id}
                        thing={thing}
                    />
                ))}
            </tbody>
        </table>
    );
}

export default ListThings;