import React from "react";
// import { BrowserRouter as Router, Switch, Route, HashRouter } from 'react-router-dom';
import { Switch, Route, HashRouter } from "react-router-dom";
import Login from "./components/auth/Login";
import Dashboard from "./components/dashboard/Dashboard";
import Things from "./components/things/Things";
import ThingsHistory from "./components/things/History";

import ThingsState from "./context/things/thingsState";
import AuthState from "./context/auth/authState";
import AlertState from "./context/alerts/alertState";

import tokenAuth from "./config/token";
import PrivateRoute from "./components/routes/PrivateRoute";
// import Thing from './components/things/Thing';

function App() {
  //                                                        //Revisar si tenemos un token
  const token = localStorage.getItem("token");
  if (token) {
    tokenAuth(token);
  } 

  return (
    <ThingsState>
      <AlertState>
        <AuthState>
          <HashRouter>
            <Switch>
              <Route exact path="/" component={Login} />
              <PrivateRoute exact path="/dashboard" component={Dashboard} />
              <PrivateRoute exact path="/things" component={Things} />
              <PrivateRoute exact path="/things/history/:idThing" component={ThingsHistory}/>
            </Switch>
          </HashRouter>
        </AuthState>
      </AlertState>
    </ThingsState>
  );
}

export default App;
