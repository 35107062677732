import React, { useReducer } from "react";
import AuthContext from "./authContext";
import AuthReducer from "./authReducer";

import clientAxios from "../../config/axios";
import tokenAuth from "../../config/token";

import {
  REQUEST_LOGIN,
  GET_USER,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOG_OUT,
  CLEAR_MESSAGES,
} from "../../types";

const AuthState = (props) => {
  const initialState = {
    token: localStorage.getItem("token"),
    authenticated: true,
    user: null,
    msg: null,
    loading: false,
  };

  const [state, dispatch] = useReducer(AuthReducer, initialState);

  /**
   * Retorna el usuario logueado
   */
  const userAuthenticated = async () => {
    //                                                      //Se verifica si hay un token guardado en el explorador.
    const token = localStorage.getItem("token");

    // console.log('userAuthenticated - authState');

    if (token) {
      tokenAuth(token);
    }

    try {
      // const response = await clientAxios.get("api/v1/auth");
      const response = await clientAxios.get("users");

      // console.log(response.data);

      dispatch({
        type: GET_USER,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: LOGIN_ERROR,
        payload: error
      });
    }
  };

  /**
   * Enviamos al API la autenticación del usuario.
   *
   * @param {*Data} data
   */
  const loginUser = async (data) => {
    try {
      //                                              //Se avisa que se va a iniciar una petición al API.
      // dispatch({ type: REQUEST_LOGIN });

      // console.log('Aqui hijo de puta');

      //                                              //Se llama al API para autenticar el usuario.
      // const response = await clientAxios.post("api/v1/auth", data.user);
      const response = await clientAxios.post("users/login", data.user);

      // console.log(response.data);

      //                                              //Si todo salio bien, se avisa que el usuario fue logueado
      //                                              //      con éxito.
      dispatch({
        type: LOGIN_SUCCESS,
        payload: response.data,
      });

      //                                              //Se obtener el usuario.
      // userAuthenticated();
    } catch (error) {
      dispatch({
        type: LOGIN_ERROR,
        payload: error.response.data,
      });

      clearMessage();
    }
  };

  const logoutUser = async (id) => {
    try {
        //                                                  //Se llama al API para autenticar el usuario.
        // const response = await clientAxios.put("api/v1/auth/"+id);
        const response = await clientAxios.post("users/logout");

        dispatch({
          type: LOG_OUT,
          payload: response.data,
        });

    } catch (error) {
      console.log(error);
    }

  };

  const clearMessage = () => {
    const timer = setTimeout(() => {
      //                                              //Se avisa que se va a iniciar una petición al API.
      dispatch({ type: CLEAR_MESSAGES });
    }, 2500);
    return () => clearTimeout(timer);
  };

  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        authenticated: state.authenticated,
        user: state.user,
        msg: state.msg,
        loading: state.loading,
        loginUser,
        userAuthenticated,
        logoutUser,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
export default AuthState;
