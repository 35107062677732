import React from 'react';
import Sidebar from '../layout/Sidebar';
import Topbar from '../layout/Topbar';
import ListThings from './ListThings';

const Things = () => {

    const sidebar = {
        navItem: 'Things'
    }


    return (
        <div className="app-container">
            <Sidebar
                sidebar={sidebar}
            />
            <div className="main-section">
                <Topbar />
                <main>
                    <div className="container-fluid">
                        {/* Page Heading */}
                        <h1 className="h3 mb-0 text-gray-800">Things</h1>
                        <p className="mb-4">En esta tabla se muestran los dispositivos que se tienen agregados en todo el proyecto</p>

                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary">Dispositivos</h6>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <ListThings /> 
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}

export default Things;