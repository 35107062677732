import React, { useContext, useEffect } from 'react';
import Sidebar from '../layout/Sidebar';
import Topbar from '../layout/Topbar';
import AuthContext from '../../context/auth/authContext';
import GoogleMaps from './GoogleMaps/GoogleMaps';
import ChartBar from './charts/ChartBar';
import ChartPie from './charts/ChartPie';
import ChartLine from './charts/ChartLine';
import Cards from './cards/Cards';
import { Coverage } from './Coverage';

const Dashboard = () => {

    //                                                      //Extraer la información de autenticación
    const authContext = useContext(AuthContext);
    const { user, userAuthenticated } = authContext;

    useEffect(() => {

        // eslint-disable-next-line
    }, [user])

    const sidebar = { navItem: 'Dashboard' }

    return (
        <div className="app-container">
            <Sidebar
                sidebar={sidebar}
            />
            <div className="main-section">
                <Topbar />
                <main>
                    <div className="container-fluid">
                        {/* Page Heading */}
                        <div className="d-sm-flex align-items-center justify-content-between mb-4">
                            <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
                        </div>

                        {/* Tarjetas con indicadores */}
                        <Cards
                        user={user}
                        />

                        {/* Mapas */}
                        {/* <GoogleMaps /> */}
                        <Coverage/>

                        {/* Gráficas */}
                        {/* <div className="row">
                            <ChartBar />
                            <ChartPie />
                            <ChartLine />
                        </div> */}
                    </div>
                </main>
            </div>

        </div>
    );
}

export default Dashboard;