import {
    GET_USER,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    LOG_OUT,
    REQUEST_LOGIN,
    CLEAR_MESSAGES
} from '../../types';


export default (state, action) => {
    switch (action.type) {
        case REQUEST_LOGIN:
            return{
                ...state,
                loading: true
            }
        case LOGIN_SUCCESS:
            localStorage.setItem('token', action.payload.token);
            return {
                ...state,
                authenticated: true,
                // msg: null,
                loading: false
            }
        case LOG_OUT:
        case LOGIN_ERROR:
            localStorage.removeItem('token');
            return {
                ...state,
                token: null,
                user: null,
                authenticated: null,
                msg: action.payload.msg,
                loading: false
            }
        case GET_USER:
            return {
                ...state,
                user: action.payload,
                authenticated: true,
                loading: false
            }
        case CLEAR_MESSAGES:
            return{
                ...state,
                loading: false,
                msg: null
            }
        default:
            return state;
    }
}