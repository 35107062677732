import { GET_THINGS, GET_HISTORIES_THINGS } from '../../types';

export default (state, action) => {
    switch (action.type) {
        case GET_THINGS:
            // console.log('state')
            // console.log(action)
            return {
                ...state,
                things: action.payload,
                loading: true
            }
        case GET_HISTORIES_THINGS:
            return{
                ...state,
                thingsHistory: action.payload,
                loading: false
            }
        default:
            return state;
    }
}