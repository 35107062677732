import React, { useState, useContext, useEffect } from "react";
import AuthContext from "../../context/auth/authContext";
import AlertContext from "../../context/alerts/alertContext";
import { browserName, browserVersion, osName, osVersion } from "react-device-detect";

const Login = (props) => {
  //                                                        //Context para la autenticación
  const authContext = useContext(AuthContext);
  const { msg, authenticated, loginUser, loading } = authContext;

  //                                                        //Context para mostrar las alertas.
  const alertContext = useContext(AlertContext);
  const { alert, showAlert } = alertContext;

  //                                                        //State para obtener la información del usuario.
  const [user, saveUser] = useState({
    email: "",
    password: "",
    browser: browserName,
    browserVersion,
    osName,
    osVersion
  });

  //                                                        //Extraemos el usuario del State.
  const { email, password } = user;

  useEffect(() => {
    //                                                      //Si el usuario ya esta autenticado
    if (authenticated) {
      //                                                    //Nos dirige al dashboard directo.
      props.history.push("./dashboard");
    }

    //                                                      //Si hay un error cuando se envia información al servidor
    //                                                      // mostrarmos un mensaje.
    if (msg) {
      showAlert(msg, "alert-danger");
    }
    // eslint-disable-next-line
  }, [authenticated, props.history, msg]);

  /**
   * Para poder obtener lo que se escribe en los inputs y
   *    guardarlos en el State.
   *
   * @param {*} e
   */
  const onChange = (e) => {
    saveUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  /**
   * Acción cuando se presione el botón Login del formulario
   *
   * @param {*} e
   */
  const onSubmit = (e) => {
    e.preventDefault();
                                                         //Se valida que no haya campos vacios.
    if (email.trim() === "" || password.trim() === "") {
      showAlert("Todos los campos son obligatorios", "alert-danger");
      return;
    }

    

    //                                                      //Se pasa al State para ejecutar la acción.
    loginUser({ user });
  };


  return (
    <div className="container col-lg-4">
      <div className="card o-hidden border-1 shadow-lg my-4">
        <div className="col-lg-12">
          <div className="p-5">
            <div className="text-center pb-4">
              <img
                className="img-logo"
                src={process.env.PUBLIC_URL + "/logo.png"}
                alt="Logo Synergi"
              ></img>
            </div>
            <form className="user" onSubmit={onSubmit}>
              <div className="form-group">
                <input
                  className="form-control form-control-user"
                  type="email"
                  id="email"
                  name="email"
                  aria-describedby="emailHelp"
                  placeholder="Ingresa tu correo eléctronico"
                  value={email}
                  onChange={onChange}
                />
              </div>
              <div className="form-group">
                <input
                  className="form-control form-control-user"
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Contraseña"
                  autoComplete="on"
                  value={password}
                  onChange={onChange}
                />
              </div>

              <button
                disabled={loading}
                className="btn btn-primary btn-user btn-block"
              >
                {loading ? (
                  <span className="spinner-border spinner-border-sm mr-1"></span>
                ) : (
                  'Iniciar sesión'
                )}
              </button>
            </form>
          </div>
          {alert ? (
            <div className={`alert ${alert.category}`} role="alert">
              {alert.msg}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Login;
