//                                                          //Referente a Things
export const GET_THINGS = 'GET_THINGS';
export const GET_HISTORIES_THINGS = 'GET_HISTORIES_THINGS';

//                                                          //Alertas
export const SHOW_ALERT = 'SHOW_ALERT';
export const HIDE_ALERT = 'HIDE_ALERT';

//                                                          //Mensajes
export const SHOW_MESSAGE = 'SHOE_MESSAGE';

//                                                          //Información de los usuarios.
// export const CREATE_USER = 'CREATE_USER';
// export const CREATE_USER_ERROR = 'CREATE_USER_ERROR';
export const GET_USER = 'GET_USER';

//                                                          //Autenticación
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOG_OUT = 'LOG_OUT';
export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';