import React, { useContext, useEffect } from "react";
import Topbar from "../layout/Topbar";
import thingsContext from "../../context/things/thingsContext";
import ChartamLine from "../dashboard/charts/ChartamLine";

const History = ({ match }) => {
  //                                                        //Extrae los dispositivos del state inicial.
  const thingContext = useContext(thingsContext);
  const { thingsHistory, getHistoryThing, loading } = thingContext;

  //                                                        //Cuando carga el componente
  useEffect(() => {
    getHistoryThing(match.params.idThing)

    // eslint-disable-next-line
  }, [loading]);

  return (
    <div className="main-section">
      <Topbar />
      <main>
        <div className="container-fluid">
          {/* Page Heading */}
          {/* <div className="row"> */}
          {/* <button onClick={history.goBack} className="btn btn-circle btn-sm btn-back">
                        &#8249;
                        </button> */}
          <h1 className="h3 mb-0 text-gray-800">Dispositivo</h1>
          {/* </div> */}
          <p className="mb-4">Se muestra el historial del dispositivo</p>
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">Historial</h6>
            </div>
            
            
            {
              loading ? 
              <div className="loading-api"><h5 className="m-0">Cargando gráfica...</h5></div> : 
              <ChartamLine
              key={match.params.idThing}
              thingsHistory={thingsHistory}
            />
            }
            {/* <ChartamLine
              key={match.params.idThing}
              thingsHistory={thingsHistory}
            /> */}
          </div>
        </div>
      </main>
    </div>
  );
};

export default History;
