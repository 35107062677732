import React, {useContext} from 'react';
import AuthContext from '../../context/auth/authContext';

const LogoutModal = () => {

    //                                                        //Extraer la informaci´ón de autenticación
    const authContext = useContext(AuthContext);
    const {user, logoutUser } = authContext;

    // const [hidden, setHidden] = useState(false);

    const btnLogOut = () => {
        logoutUser(user.idUser)
    }

    return (
        <div className="modal fade" id="logoutModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">¿Listo para cerrar sesión?</h5>
                        <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">Presiona el botón  de "Cerrar sesión" si quieres finalizar tu sesión actual.</div>
                    <div className="modal-footer">
                        <button className="btn btn-secondary" type="button" data-dismiss="modal">Cancelar</button>
                        {/* <a className="btn btn-primary" href="./" onClick={()=> btnLogOut()}>Cerrar sesión</a> */}
                        <button className="btn btn-primary" type="button" onClick={()=> btnLogOut() } data-dismiss="modal">Cerrar sesión</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LogoutModal;
