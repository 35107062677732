// import { path } from "@amcharts/amcharts4/core";
import React from "react";
import { useHistory } from "react-router-dom";

const Thing = ({ thing }) => {
  const history = useHistory();

  const onSelectRow = (e) => {
    e.preventDefault();
    //
    // console.log('Se seleciono: ' + thing.idModule);
    history.push(`/things/history/${thing.idThing}`);
    // history.push(`/things/history/${thing}`);
  };

  //@ts-check
  const formatDate = (dateString) => {
    // const options: Intl.DateTimeFormatOptions = {
    //   //Typescript ways of adding the type
    //   year: "numeric",
    //   month: "long",
    //   day: "numeric",
    //   hour: "numeric",
    //   minute: "numeric",
    //   second: "numeric",
    // };
    // const addTime = 21600; //6 Horas
    // var date = new Date(dateString).toLocaleString();

    // var fecha = new Date();
    // const date = new Date(dateString);
    // var numberOfMlSeconds = date.getTime();

    // let date = new Date(Date.UTC(dateString));
    // console.log('Date in India: ' + date);
    // let formatter = new Intl.DateTimeFormat('en-US', { timeZone: "America/New_York" });   
    // let usDate = formatter.format(date);
    // console.log('Date in USA: ' + usDate);
    

      // return new Date(dateString);
    // return dateString;//;([], options); //e.g 31/7/2020 11:44:42
    return new Date(dateString).toLocaleString();//;([], options); //e.g 31/7/2020 11:44:42
    // return new Date(dateString).toLocaleTimeString();//;([], options); //e.g 31/7/2020 11:44:42
    // return new Date(dateString).toDateString();//;([], options); //e.g 31/7/2020 11:44:42
    // return new Date(dateString).toDateString();//;([], options);//e.g Fri Jul 31 2020
    // return new Date(dateString).toLocaleDateString([], options);
  };

  return (
    <tr onClick={onSelectRow}>
      <td key={thing.idModule}>{thing.idModule}</td>
      <td key={thing.alias}>{thing.alias}</td>
      <td key={thing.data}>{thing.data}</td>
      <td key={thing.lastUpdateInfo}>{formatDate(thing.lastUpdateInfo)}</td>
      {/* <td key={thing.lastUpdateInfo}>{thing.lastUpdateInfo}</td> */}
    </tr>
  );
};

export default Thing;
