import React from 'react';

const ProfileModal = () => {
    return (
        <div className="modal fade" id="profileModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Perfil</h5>
                        <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">Correo electrónico</div>
                        <h5>gzamudio182@hotmail.com</h5>
                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">Nombre</div>
                        <h5>Gerardo Zamudio</h5>

                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-secondary" type="button" data-dismiss="modal">Cancelar</button>
                        {/* <a className="btn btn-primary" href="./" onClick={()=> btnLogOut()}>Cerrar sesión</a> */}
                        {/* <button className="btn btn-primary" type="button" onClick={()=> btnLogOut() } data-dismiss="modal">Cerrar sesión</button> */}
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default ProfileModal;