import React from 'react';
import { Link } from 'react-router-dom';

const Sidebar = ({sidebar}) => {

    //                                                      //Se obtiene de que item del naviagtion esta selecionando la
    //                                                      //  la opción.
    const { navItem } = sidebar

    return (
        <aside className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion">
            {/* <!-- Sidebar - Brand --> */}
            <Link className="sidebar-brand d-flex align-items-center justify-content-center" to={'/dashboard'}>
                <div className="sidebar-brand-text mx-3">Synergi <sup>IoT</sup></div>
            </Link >

            {/* -- Divider -- */}
            <hr className="sidebar-divider"></hr>

            {/* -- Heading -- */}
            <div className="sidebar-heading">
                Menú
            </div>

            {/* -- Nav Item - Dashboard -- */}
            <li className={navItem === 'Dashboard' ? "nav-item active" : "nav-item"}>
            {/* <li className="nav-item active"> */}
                <Link to={'/dashboard'} className="nav-link">
                    <i className="fas fa-fw fa-tachometer-alt"></i>
                    <span>Dashboard</span>
                </Link>
            </li>

            {/* -- Nav Item - Things -- */}
            <li className={navItem === 'Things'? "nav-item active" : "nav-item"}>
            {/* <li className="nav-item"> */}
                <Link to={'/things'} className="nav-link">
                    <i className="fas fa-fw fa-table"></i>
                    {/* {setNavItem('Things')} */}
                    <span>Things</span>
                </Link>
            </li>

            {/* -- Nav Item - Alerts -- */}
            <li className="nav-item">
                {/* <Link to={'/'} className="nav-link">
                    <i className="fas fa-fw fa-chart-area"></i>
                    <span>Alerts</span>
                </Link> */}
            </li>
        </aside>
    );
}

export default Sidebar;